
import { defineComponent, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useAdmins, useDeleteAdmin, useEnableAdmin, useDisableAdmin, useResetAdmin2FA } from '@/composables/api';
import { AdminIdOption, DEFAULT_PAGE_SIZE, DELETED_SUCCESSFULLY_TEXT } from '@/apis';
import { AdminStatus } from '@/interfaces/Admin';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { formatLocalTime } from '@/utils/format-time';
import { getStatusTagType } from '@/utils/render';
// import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';

// const FILTER_OPTIONS: FilterOption[] = [
//   {
//     type: FilterType.SELECTOR,
//     label: 'Status',
//     placeholder: 'Please select an option',
//     options: [
//       {
//         label: 'Enabled',
//         value: AdminStatus.ENABLED
//       },
//       {
//         label: 'Disabled',
//         value: AdminStatus.DISABLED
//       }
//     ]
//   }
// ];

export default defineComponent({
  components: {
    SearchBox,
    // Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const pageSize = ref(DEFAULT_PAGE_SIZE);

    const { data, isLoading, isFetching, refetch } = useAdmins({ page, pageSize, keyword });
    const { isLoading: isDeleting, mutate } = useDeleteAdmin();
    const { isLoading: isResetting, mutate: reset } = useResetAdmin2FA();
    const { isLoading: isEnabling, mutate: enableMutate } = useEnableAdmin();
    const { isLoading: isDisabling, mutate: disableMutate } = useDisableAdmin();

    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    // const handleFilterChange = (event: FilterEvent) => {
    //   // mutate ref
    //   status.value = event[0];
    // };

    const deleteAdmin = ({ adminId }: AdminIdOption, index: Number) => {
      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { adminId },
            {
              onSuccess() {
                refetch.value();

                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.error.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const resetAdmin2FA = ({ adminId }: AdminIdOption, index: Number) => {
      ElMessageBox.confirm('是否確認要重設二維驗證碼？', '警告', {
        confirmButtonText: '重設',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          reset(
            { adminId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: '重設驗證碼成功'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.error.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const enableAdmin = ({ adminId }: AdminIdOption, index: Number) => {
      statusIndex.value = index;
      enableMutate({ adminId }, {
        onSuccess() {
          refetch.value();
          ElMessage({
            type: 'success',
            message: '啟用成功'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.error.message,
            type: 'error'
          });
        }
      });
    };

    const disableAdmin = ({ adminId }: AdminIdOption, index: Number) => {
      statusIndex.value = index;

      disableMutate({ adminId }, {
        onSuccess() {
          refetch.value();
          ElMessage({
            type: 'success',
            message: '禁用成功'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.error.message,
            type: 'error'
          });
        }
      });
    };

    return {
      AdminStatus,
      page,
      isLoading,
      isFetching,
      data,
      isDeleting,
      isResetting,
      isEnabling,
      isDisabling,
      statusIndex,
      enableAdmin,
      disableAdmin,
      searchKeyword,
      formatLocalTime,
      deleteAdmin,
      resetAdmin2FA,
      getStatusTagType
      // handleFilterChange,
      // FILTER_OPTIONS
    };
  }
});
