import { StringNumber } from '@/apis';

export enum StatusOptions {
  UNPAID = 'unpaid',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXPIRED = 'expired',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DRAFT = 'draft',
  REVIEWING = 'reviewing',
  PROCESSING = 'processing',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  SCHEDULING = 'scheduling',
  RUN_OUT = 'run_out',
  TAKEN_DOWN = 'taken_down',
  SUBSCRIBING = 'subscribing',
  SUBSCRIBE_CANCEL = 'subscribe_cancel',
  SUBSCRIBE_EXPIRE = 'subscribe_expire',
  AUDITING = 'auditing',
  AUDITED = 'audited'
}

export const getStatusTagType = (status: StatusOptions[keyof StatusOptions]) => {
  switch (status) {
    case StatusOptions.DRAFT:
    case StatusOptions.EXPIRED:
    case StatusOptions.UNPAID:
    case StatusOptions.SCHEDULING:
    case StatusOptions.TAKEN_DOWN:
    case StatusOptions.SUBSCRIBE_EXPIRE:
      return 'info';
    case StatusOptions.REVIEWING:
      return 'warning';
    case StatusOptions.REJECTED:
    case StatusOptions.FAILED:
    case StatusOptions.DISABLED:
    case StatusOptions.SUBSCRIBE_CANCEL:
      return 'danger';
    case StatusOptions.PUBLISHED:
    case StatusOptions.ENABLED:
    case StatusOptions.SUCCESS:
    case StatusOptions.SUBSCRIBING:
    case StatusOptions.AUDITED:
      return 'success';
    default:
      return '';
  }
};
/**
 * 新增千分號符號
 * ex:1,000
 */
export const getThousandSeparator = (stringNumber: StringNumber) => {
  const regex = new RegExp('(\\d{1,3})(?=(\\d{3})+(?:$|\\D))', 'g');
  return stringNumber.replace(regex, '$1,');
};
